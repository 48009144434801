<template>
  <picture>
    <source v-for="source in props.sources" :key="source.url" :srcset="getImageUrl(source.url)" :type="source.type" />
    <img
      :src="getImageUrl(props.fileName || props.src)"
      :height="props.height"
      :width="props.width"
      :class="`${props.class && props.class !== 'img-fluid' ? props.class : imgClass}`"
      :alt="props.alt"
      @error.once="lostImage"
    />
  </picture>
</template>

<script setup>
// <!-- <v-img
//   v-bind="$attrs"
//   :src="getImageUrl(props.fileName || props.src)"
//   :width="width"
//   :class="`${props.class && props.class !== 'img-fluid' ? props.class : imgClass}`"
//   :alt="alt"
// >
//   <template #sources>
//     <source v-for="source in sources" :key="source.url" :srcset="getImageUrl(source.url)" :type="source.type" />
//   </template>
//   <template #error>
//     <slot name="error"></slot>
//   </template>
//   <template #placeholder>
//     <div class="d-flex align-center justify-center fill-height">
//       <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
//     </div>
//   </template>
// </v-img> -->

defineOptions({ inheritAttrs: false });
const props = defineProps({
  fileName: { type: String, required: false, default: "" },
  src: { type: String, required: false, default: "" },
  sources: { type: Array, required: false, default: () => [] },
  width: { type: [String, Number], required: false, default: "100%" },
  height: { type: [String, Number], required: false, default: "100%" },
  position: { type: String, required: false, default: "left" },
  alt: { type: String, required: false, default: "" },
  class: { type: String, required: false, default: "" },
  srcErr: { type: String, required: false, default: "" },
});
const page = inject("page");
const getImageUrl = source => (process.env.NODE_ENV === "development" ? `//${unref(page).hostname}${source}` : source);
const imgClass = computed(() => (props.position ? `img-fluid pos-${props.position}` : "img-fluid"));

const lostImage = e => {
  // console.log("lostImage", e);
  while (e.target?.previousElementSibling?.nodeName === "SOURCE")
    e.target.parentNode.removeChild(e.target.previousElementSibling);
  e.target.src = new URL(getImageUrl(props.srcErr), e.target.src).href;
};
</script>
